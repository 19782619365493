.audioImageContainer {
    position: relative;
    width: auto;
}

.audioImageContainer img {
    width: 100%;
    height: auto;
}

.audioImageContainer .playButton {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: #555;
    color: white;
    font-size: 16px;
    padding: 12px 24px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
}

.audioImageContainer .playButton:hover {
    background-color: black;
}