#competences {
    background: #4f80d8;
    -webkit-text-fill-color: white;
    text-align: center;
}

.centerDiv {
    max-width: 100vw;
}

hr {
    border: 1px solid rgba(255, 255, 255, 0.51);
}