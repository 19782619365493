#banner {
    text-align: center;
    background: #39465d;
}

#bannerTitle {
    font-size: xxx-large;
    font-weight: bold;
    color: #fff;
}

#bannerText {
    color: #fff;
    font-style: italic;
    font-weight: 600;
}

.linkButton {
    background: #ffffff none repeat scroll 0 0;

    border: 1px solid #aa8d56;

    color: #aa8d56;

    outline: medium none;

    padding: 15px;

    text-decoration: none;

    display: inline-block;
}

.linkIcons {
    list-style: none;
    padding-top: 20px;
}

.linkIcons li {
    display: inline;
}