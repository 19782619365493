.videoDescription {
    font-size: smaller;
}

.video {
    border-radius: .25rem;
}

.videoThumbnail {
    width: 100%;
}

.videoText {
    padding-left: 10px;
    padding-right: 10px;
    fill: black;
    -webkit-text-fill-color: black;
}

.card {
    height: 100%;
    margin: 1rem;
}