#specificSkill {
    display: inline-flex;
    /*max-width: 100vw;*/
    max-width: 30em;
    text-align: start;
    margin: 2em 1em;
}

hr {
    /*width: 70%;*/
    margin: auto;
}

.skillTitle {
    text-align: center;
}

ul {
    list-style: "-";
    text-indent: 6px;
}

ul > li {
    padding-block-end: 0.5em;
}

.skillIconWrapper {
    margin-right: 20px;
    text-align: -webkit-center;
}

.skillImage {
    width: 4em;
    filter: invert(100%) sepia(100%) saturate(1%) hue-rotate(150deg) brightness(108%) contrast(98%);
}

@media (max-width: 1150px) {
    #specificSkill {
        display: inline-block;
    }
}