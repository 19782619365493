#contact {
    background: #052026c7;
    -webkit-text-fill-color: white;
    min-height: auto;
    height: fit-content;
}

.emailWrapper {
    margin: 5em;

    justify-self: center;
    min-width: 400px;
    max-width: 1000px;
}

.emailAdress h2 {
    text-align: center;
}

@media (max-width: 767px) {
    .emailWrapper {
        margin: 1em;
        min-width: 300px;
    }
}

@media (max-width: 700px) {
    .emailAdress h2 {
        font-size: large;
    }
}

@font-face {
    font-family: neon;
    src: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/707108/neon.ttf);
}

.neon {
    font-family: neon, serif;
    color: #FB4264;
    text-shadow: 0 0 3vw #F40A35;
}

.neon {
    animation: neon 2s ease infinite;
    -moz-animation: neon 2s ease infinite;
    -webkit-animation: neon 2s ease infinite;
}

@keyframes neon {
    0%,
    100% {
        text-shadow: 0 0 1vw #FA1C16, 0 0 3vw #FA1C16, 0 0 10vw #FA1C16, 0 0 10vw #FA1C16, 0 0 .4vw #FED128, .5vw .5vw .1vw #806914;
        color: #f61ba5;
    }
    50% {
        text-shadow: 0 0 .5vw #800E0B, 0 0 1.5vw #800E0B, 0 0 5vw #800E0B, 0 0 5vw #800E0B, 0 0 .2vw #800E0B, .5vw .5vw .1vw #40340A;
        color: #806914;
    }
}
