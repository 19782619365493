#description {
    /*background: #052026c7;*/
    background: radial-gradient(ellipse at top, #052026c7, transparent),
    radial-gradient(ellipse at bottom, #4e004e, transparent);;
    -webkit-text-fill-color: white;
    grid-template-rows: auto auto 1fr 1fr 1fr auto auto;
    min-height: 100vh;
}

.container {
    display: inline-flex;
    max-width: 100vw;
    margin-top: 14em;
    margin-bottom: 8em;
}

.innerContainer {
    width: 100%;
}

.imageCol {
    padding: 4em;
    width: 30%;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    margin-left: 14%;
    align-items: center;
}

.textCol {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    padding-right: 5em;
    max-width: 1000px;
    opacity: .9;

}

.imageCol img {
    width: 100%;
}

@media (max-width: 1250px) {
    .container {
        margin-top: 14em;
    }

    .imageCol {
        margin-left: 0;
        padding: 1em;
    }
}


@media (max-width: 1000px) {
    .container {
        flex-direction: column-reverse;

        margin-top: 4em;
        margin-bottom: 4em;
    }

    .imageCol,
    .textCol {
        width: 100%;

        padding-right: 1em;
        padding-left: 1em;
        margin-left: 0;
    }

    .imageCol img {
        max-width: 600px;
        width: 100%;
    }
}
