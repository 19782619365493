#videos {
    background: radial-gradient(ellipse at bottom, #432769, transparent);
    -webkit-text-fill-color: white;
    height: fit-content;
    min-height: auto;
}

.flex-box {
    display: flex;
    flex-flow: row wrap;
}

.flex-box #myCarousel {
    flex: 1 1 50%
}

.flex-box #desc {
    flex: 1 1 50%;
}

.row {
    max-width: 100vw;
    margin-left: 0;
    margin-right: 0;
}

.video-list {
    margin-bottom: 2em;
    flex-wrap: wrap;
    display: flex;
}

.video-card-list-component {
    margin-bottom: 2rem !important;
}

.fill-available {
    height: 100%;
}

.ecommerce-video-gallery {
    width: 75%;
    max-width: 75%;
    min-width: 75%;
    justify-self: center;
}