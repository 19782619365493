* {
    margin: 0;
    padding: 0;

    font-size: large;
    font-family: 'Roboto Light', sans-serif;
    scroll-behavior: smooth;
}

section {
    min-height: 100vh;
    display: grid;
    overflow: auto;
}

.sectionTitle {
    text-align: center;
    font-style: italic;
    text-decoration: underline;
    font-size: xx-large;
    margin-block-start: 2.3em;;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: black;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.topSection {
    margin-block-start: 6em;
}

.lowerSection {
    align-self: flex-end;
    margin-block-end: 6em;
}

.leftSkewedSeparator {
    transform: skew(
            360deg,
            354deg
    );
    z-index: 10;
    position: relative;
    height: 10em;
}

.rightSkewedSeparator {
    transform: skew(
            360deg,
            6deg
    );
    z-index: 10;
    position: relative;
    height: 10em;
}

.blueSection {
    background: #4f80d8;
}

.purpleSection {
    background: radial-gradient(ellipse at top, #432769, transparent);;
}

.darkSection {
    background: #052026c7;
}